<!--
 * @Description: 商品分类列表页面
 * @Author: 胡蒙川
 * @Date: 2019-06-04 14:06:49
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-08-02 17:56:19
 -->
<template>
  <div class="goodsCategoryList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-select2 v-if="!isCommunityUser" label="所属公司" v-bind="regionParams" v-model="searchParams.regionId" />
      </template>
      <template #operateSlot="scope">
        <v-button text="配置分类" type="text" permission="view" @click="config(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getCategoryCountListURL } from './api'
import { regionParams } from 'common/select2Params'

export default {
  name: 'GoodsCategoryList',
  data () {
    return {
      searchUrl: getCategoryCountListURL,
      regionParams,
      searchParams: {
        regionId: ''
      },
      headers: [
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'normalOneCategoryCount',
          label: '正常一级分类'
        },
        {
          prop: 'normalTwoCategoryCount',
          label: '正常二级分类'
        },
        {
          prop: 'operatorUser',
          label: '操作人'
        },
        {
          prop: 'operatorTime',
          label: '操作时间'
        }
      ]
    }
  },
  computed: {
    isCommunityUser () {
      let userType = String(this.$store.state.userInfo.userType)
      return userType === '101' || userType === '102'
    }
  },
  methods: {

    // 配置分类
    config (row) {
      this.$router.push({
        name: 'goodsCategoryFirstList',
        query: {
          regionId: row.regionId,
          regionName: row.regionName
        }
      })
    }
  }
}
</script>

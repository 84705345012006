var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsCategoryList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                !_vm.isCommunityUser
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "所属公司" },
                          model: {
                            value: _vm.searchParams.regionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "regionId", $$v)
                            },
                            expression: "searchParams.regionId",
                          },
                        },
                        "v-select2",
                        _vm.regionParams,
                        false
                      )
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "配置分类", type: "text", permission: "view" },
                  on: {
                    click: function ($event) {
                      return _vm.config(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }